<template>
  <div>
    <span>创建时间：</span>
    <el-date-picker
      class="mr20"
      clearable
      v-model="timeArr"
      type="datetimerange"
      range-separator="至"
      start-placeholder="开始时间"
      end-placeholder="结束时间"
      value-format="timestamp"

      >
    </el-date-picker>
    <span>任务状态：</span>
    <el-select ref="formStatus" v-model="form.status" placeholder="请选择任务状态" clearable class="mr20">
      <el-option
        v-for="item in IMPORT_STATUS"
        :key="item.value"
        :label="item.label"
        :value="item.value">
      </el-option>
    </el-select>
    <el-button type="primary" @click="currentChange(1)">查询</el-button>
    <el-table
      class="mb20"
      :data="tableData"
      style="width: 100%">
      <el-table-column prop="bussinessType" label="任务类型" >
        <template slot-scope="{row}">
          {{$changeObj(BUSSINESSTYPE_STATUS)[row.bussinessType]}}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" >
        <template slot-scope="{row}">
          {{row.createTime | timeToDate}}
        </template>
      </el-table-column>
      <el-table-column prop="name" label="文件名" />
      <el-table-column prop="status" label="任务状态" >
        <template slot-scope="{row}" >
          {{$changeObj(IMPORT_STATUS)[row.status]}}
        </template>
      </el-table-column>
      <el-table-column prop="filePath" label="下载链接" >
         <template slot-scope="{row}" v-if="+row.status !== 3">
          <!-- <a  :href="row.newFilePath" :download="row.newFilePath" style="color:blue" target="blank">{{row.newFilePath}}</a> -->
          <span v-if="row.filePath" @click="downBtn(row.newFilePath)" style="color:blue;cursor:pointer">{{row.newFilePath}}</span>

        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" >
        <template slot-scope="{row}">
          <el-button type="primary" size="mini" @click="downBtn">下载</el-button>
        </template>
      </el-table-column> -->
    </el-table>
     <div class="df fe">
      <el-pagination
          layout="prev, pager, next, total"
          :total="total"
          :current-page="form.beginIndex + 1"
          :page-size="form.pageSize"
          @current-change="currentChange">
      </el-pagination>
     </div>

  </div>
</template>

<script>
import axios from 'axios';
import {queryExportTask,listExport} from '@/api/dataStatement'
import {IMPORT_STATUS,BUSSINESSTYPE_STATUS} from '@/js/constant'
export default {
  data() {
    return {
      IMPORT_STATUS,
      BUSSINESSTYPE_STATUS,
      tableData:[],
      total:1,
      form:{
        beginIndex:0,
        pageSize:20,
        startTime:'',
        endTime:'',
        status:'',
        origin:''
      },
      timeArr:[]
    }
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted(){
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.formStatus.blur()
        _this.queryExportTask()
      }
    };
    this.queryExportTask();
    this.origin = location.origin;
  },
  methods: {
    async downBtn(path){
      window.location.href = path;
    },
    currentChange(index){
      this.form.beginIndex = index - 1;
      this.queryExportTask();
    },
    async queryExportTask(){


      try{
        if(this.timeArr && this.timeArr.length > 0){
          this.form.startTime = this.timeArr[0];
          this.form.endTime = this.timeArr[1];
        }else{
          this.form.startTime = null;
          this.form.endTime = null;
        }
        const params = {...this.form};
        const res = await queryExportTask(params);
        res.ldata.forEach(el => {
          const arr = el.filePath.split('/');
          el.name = arr[arr.length - 1];
          el.newFilePath = process.env.NODE_ENV === 'development' ? 'https://leasehold-dev.lititi.com' + el.filePath : location.origin + el.filePath;
        });
        this.tableData = res.ldata;
        this.total = res.mdata.total;

      }catch(e){
        console.log(e,'e');
      }
    },
  },
  components: {

  }
}
</script>

<style scoped>
/* .el-button{
white-space: pre-line;
} */

</style>
